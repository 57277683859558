import React, { useState, useEffect } from 'react';
import {
  Box,
  Typography,
  Grid,
  Paper
} from '@mui/material';
import useAuthenticatedData from '../../hooks/useAuthenticatedData';
import KpisGrid from './KpisGrid';
import MetricsGrid from './MetricsGridxx';
import GoalsGrid from '../overview/GoalsGrid';
import GroupBox from '../common/GroupBox';
import { useMsal } from "@azure/msal-react";


const GridWrapper = ({ title, children }) => (
  <Paper sx={{ color: 'white', p: 2, mb: 2 }}>
    <Typography variant="h6" gutterBottom>{title}</Typography>
    {children}
  </Paper>
);

const GeneralDashboard = () => {
 

  const { instance, accounts } = useMsal();
  const [username, setUsername] = useState("");

  useEffect(() => {
    if (accounts.length > 0) {
      const name = accounts[0].name || accounts[0].username || "User";
      setUsername(name);
    }
  }, [accounts]);

  return (
    <Paper elevation={3} sx={{ p: 1, maxWidth: '100%', mt: 4 }}>
      <Typography variant="h1" component="h1" gutterBottom align="left">
        General Dashboard
      </Typography>
       
    </Paper>
  );


};

export default GeneralDashboard;