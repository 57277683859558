// src/components/CustVendDimDataGrid.jsx

import React, { useState, useMemo, useEffect, useCallback } from "react";
import { DataGrid, GridToolbarContainer, GridToolbarExport, GridToolbar } from "@mui/x-data-grid";
import { useQuery, gql } from "@apollo/client";
import { Paper, Box, Typography } from "@mui/material";
import { useNavigate, useSearchParams } from "react-router-dom";
import { enterpriseGridOverrideTheme } from '../../../themes/EnterpriseGridTheme';
import { GET_ALL_REPAIR_OPPORTUNITIES } from "../../../queries";

// Helper functions for formatting
const formatCurrency = (value) => {
  if (value === null || value === undefined) return "";
  if (value >= 1000) {
    return `$${(value / 1000).toFixed(1)}K`;
  }
  return `$${value.toFixed(2)}`;
};

const formatDate = (value) => {
  if (!value) return "";
  const date = new Date(value);
  if (isNaN(date)) return "";
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");
  const year = date.getFullYear();
  return `${month}/${day}/${year}`;
};


const RepairOpportunitiesGrid = () => {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();

  // Initialize state from URL search params
  const initialPage = parseInt(searchParams.get("page")) || 0;
  const initialPageSize = parseInt(searchParams.get("pageSize")) || 50;
  const initialSortField = searchParams.get("sortField") || "";
  const initialSortOrder = searchParams.get("sortOrder") || "";
  const initialFilterName = searchParams.get("filterName") || "";
  const initialFilterCreditLimit =
    parseInt(searchParams.get("filterCreditLimit")) || 1000000;
  const initialAdvanced = searchParams.get("advanced") === "true";

  const [paginationModel, setPaginationModel] = useState({
    page: initialPage,
    pageSize: initialPageSize,
  });
  const [sortModel, setSortModel] = useState(
    initialSortField
      ? [{ field: initialSortField, sort: initialSortOrder.toLowerCase() }]
      : []
  );
  const [filterModel, setFilterModel] = useState({
    name: initialFilterName,
    creditLimit: initialFilterCreditLimit,
    lastQuoted: "",
    lastCallTracker: "",
    lastSODate: "",
  });
  const [advanced, setAdvanced] = useState(initialAdvanced);

  const [queryVariables, setQueryVariables] = useState({
    skip: initialPage * initialPageSize,
    take: initialPageSize,
    where: {},
    order: [],
  });

  // Update query variables based on state
  useEffect(() => {
    const skip = paginationModel.page * paginationModel.pageSize;
    const take = paginationModel.pageSize;

    let where = {};
    if (filterModel.name) {
      where.name = { contains: filterModel.name };
    }
    if (filterModel.creditLimit < 1000000) {
      where.creditLimit = { lte: filterModel.creditLimit };
    }
    if (advanced) {
      if (filterModel.lastQuoted) {
        where.lastQuoted = { eq: filterModel.lastQuoted };
      }
      if (filterModel.lastCallTracker) {
        where.lastCallTracker = { eq: filterModel.lastCallTracker };
      }
      if (filterModel.lastSODate) {
        where.lastSODate = { eq: filterModel.lastSODate };
      }
    }

    let order = [];
    if (sortModel.length > 0) {
      order = sortModel.map((sort) => ({
        [sort.field]: sort.sort.toUpperCase(),
      }));
    }

    setQueryVariables({ skip, take, where, order });
  }, [paginationModel, sortModel, filterModel, advanced]);

  // Update URL search params without causing infinite loop
  useEffect(() => {
    const params = {};
    if (paginationModel.page !== 0) params.page = paginationModel.page;
    if (paginationModel.pageSize !== 50)
      params.pageSize = paginationModel.pageSize;
    if (sortModel.length > 0) {
      params.sortField = sortModel[0].field;
      params.sortOrder = sortModel[0].sort.toUpperCase();
    }
    if (filterModel.name) params.filterName = filterModel.name;
    if (filterModel.creditLimit < 1000000)
      params.filterCreditLimit = filterModel.creditLimit;
    if (advanced) params.advanced = true;

    // Create a URLSearchParams object from current searchParams
    const currentParams = new URLSearchParams(searchParams);
    const newParams = new URLSearchParams(params);

    // Compare existing searchParams with newParams
    let needUpdate = false;

    // Check if any new param differs from the current one
    newParams.forEach((value, key) => {
      if (currentParams.get(key) !== value) {
        needUpdate = true;
      }
    });

    // Check for any params that exist in currentParams but not in newParams
    currentParams.forEach((value, key) => {
      if (!newParams.has(key)) {
        needUpdate = true;
      }
    });

    if (needUpdate) {
      setSearchParams(params);
    }
  }, [
    paginationModel,
    sortModel,
    filterModel,
    advanced,
    searchParams,
    setSearchParams,
  ]);

  const { data, loading, error } = useQuery(GET_ALL_REPAIR_OPPORTUNITIES);

  const rows = useMemo(() => {
    if (!data?.repairOpportunities?.items) return [];
    return data.repairOpportunities.items.map((item, index) => ({
      id: item.logno,  
      ...item,
    }));
  }, [data]);

  const rowCount = data?.repairOpportunities?.totalCount || 0;

  const handleSortModelChange = useCallback((newSortModel) => {
    setSortModel(newSortModel);
  }, []);

  const handlePaginationModelChange = useCallback((newPaginationModel) => {
    setPaginationModel(newPaginationModel);
  }, []);

  const handleFilterChange = useCallback((newFilters) => {
    setFilterModel(newFilters);
  }, []);

  const handleResetFilters = useCallback(() => {
    setFilterModel({
      name: "",
      creditLimit: 1000000,
      lastQuoted: "",
      lastCallTracker: "",
      lastSODate: "",
    });
    setSortModel([]);
    setPaginationModel({
      page: 0,
      pageSize: 50,
    });
    setAdvanced(false);
  }, []);

  const columns = [
    { field: "partnumber", headerName: "Part Number", width: 200 },
    { field: "description", headerName: "Description", width: 300 },
    { field: "qty", headerName: "Quantity", width: 100 },
    { field: "class", headerName: "Class", width: 100 },
    { field: "ata", headerName: "ATA", width: 100 },
    { field: "location", headerName: "Location", width: 150 },
    { field: "partSsn", headerName: "Part SSN", width: 150 },
    { field: "logno", headerName: "Log Number", width: 150 },
    { field: "serialNumber", headerName: "Serial Number", width: 150 },
    { field: "condition", headerName: "Condition", width: 100 },
    { field: "receiverDate", headerName: "Receiver Date", width: 200 },
  ];
  

  const handleRowClick = useCallback(
    (params) => {
      navigate(`/repair-details?opportunityId=${params.id}`);
    },
    [navigate]
  );

  return (
    <Paper elevation={3} className="paper">

      <div className="dataGridContainer">
        {loading ? (
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            height="100%"
          >
            <Typography>Loading...</Typography>
          </Box>
        ) : (

          <DataGrid
            rows={rows}
            columns={columns}
            pageSize={100}
           // rowsPerPageOptions={[5, 10, 25]}
            disableSelectionOnClick={false}
            density="compact"
            onRowClick={handleRowClick}

            initialState={{
              pagination: {
                paginationModel: { pageSize: 100, page: 0 },
              },
              selection: {
                selectedRowIds: rows.length > 0 ? [0] : []
              }
            }}
            autoHeight={false}
            headerHeight={40}
            sx={{
              ...enterpriseGridOverrideTheme,
              '& .MuiDataGrid-cell': {
                paddingLeft: '1',
                backgroundColor: 'white',
                color: 'black',
              },
              '& .MuiDataGrid-row': {
                cursor: 'pointer',
                '&.Mui-selected': {
                  backgroundColor: 'primary.main',
                  '&:hover': {
                    backgroundColor: 'primary.dark',
                  },
                },
              },
            }}
            slots={{ toolbar: GridToolbar }}
            slotProps={{
              toolbar: {
                showQuickFilter: true,
                quickFilterProps: { debounceMs: 500 },
                csvOptions: { fileName: 'repairs.csv' },
                printOptions: { fileName: 'repairs.csv' },
              }
            }}
          />

         
        )}
      </div>
      {error && (
        <div className="error">
          Error: {error.message}
        </div>
      )}
    </Paper>
  );
};

export default RepairOpportunitiesGrid;
