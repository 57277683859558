import React from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { Paper, Box, Typography, Button } from '@mui/material';

const ModulesHome = () => {
    const columns = [
        { field: 'featureName', headerName: 'Feature Name', width: 200 },
        {
            field: 'videoLink',
            headerName: 'Video',
            width: 300,
            align: 'center', // Align the cell content
            headerAlign: 'center', // Align the header
            renderCell: (params) => (
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        height: '100%',
                    }}
                >
                    <Button
                        variant="contained"
                        sx={{
                            backgroundColor: params.value ? '#1976d2' : 'rgb(94, 94, 94)',
                            color: params.value ? '#fff' : 'rgb(0, 0, 0)',
                            cursor: params.value ? 'pointer' : 'default',
                            '&:hover': {
                                backgroundColor: params.value
                                    ? '#115293' // Hover color when enabled
                                    : 'rgb(94, 94, 94)', // Same color when disabled
                            },
                        }}
                        href={params.value}
                        target="_blank"
                        rel="noopener noreferrer"
            

                    >
                        Watch  
                    </Button>
                </Box>
            ),
        },
        {
            field: 'documentLink',
            headerName: 'Document',
            width: 300,
            align: 'center', // Align the cell content
            headerAlign: 'center', // Align the header
            renderCell: (params) => (
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        height: '100%',
                    }}
                >
                    <Button
                        variant="contained"
                        sx={{
                            backgroundColor: params.value ? '#1976d2' : 'rgb(94, 94, 94)',
                            color: params.value ? '#fff' : 'rgb(0, 0, 0)',
                            cursor: params.value ? 'pointer' : 'default',
                            '&:hover': {
                                backgroundColor: params.value
                                    ? '#115293' // Hover color when enabled
                                    : 'rgb(94, 94, 94)', // Same color when disabled
                            },
                        }}
                        href={params.value}
                        target="_blank"
                        rel="noopener noreferrer"
                      
                    >
                        View  
                    </Button>
                </Box>
            ),
        },
    ];
    
    const rows = [
        {
            id: 1,
            featureName: 'Salesperson Scorecard',
            videoLink: null,
            documentLink: 'https://itsparts-my.sharepoint.com/:w:/g/personal/zack_watts_its_aero/EYYzWcqwA-xNnZ9yx6RYIsQBkvA2k0gfZQ2P5eh-Xx6LXA?e=tceR17',
        },
        {
            id: 2,
            featureName: 'Customer Scorecard',
            videoLink: null,
            documentLink: 'https://itsparts-my.sharepoint.com/:w:/g/personal/zack_watts_its_aero/EYJ8s4Z_PDxApYYZO207kK4B9P1hBJcgI3kdqRDPv6Orew?e=pTPyc4',
        },
    ];

    return (
        <Paper elevation={3} sx={{ p: 3, maxWidth: '100%', mt: 4 }}>
            <Typography variant="h1" component="h1" gutterBottom align="left">
                Training Modules
            </Typography>

            <Box sx={{ height: 400, mt: 0 }}>
                <DataGrid
                    rows={rows}
                    columns={columns}
                    pageSize={5}
                    rowsPerPageOptions={[5]}
                    disableSelectionOnClick
                />
            </Box>
        </Paper>
    );
};

export default ModulesHome;