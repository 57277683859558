import React, { useState, useEffect } from 'react';
import {
    Box,
    Typography,
    Grid,
    Paper
} from '@mui/material';
import useAuthenticatedData from '../../../hooks/useAuthenticatedData';
import KpisGrid from '../KpisGrid';
import MetricsGrid from '../MetricsGridxx';
import GoalsGrid from '../../overview/GoalsGrid';
import GroupBox from '../../common/GroupBox';
import { useMsal } from "@azure/msal-react";
import { BrowserRouter as Router, Routes, Route, useParams, Navigate, useLocation } from 'react-router-dom';




const RepairDetails = () => {

    const { search } = useLocation();
    const queryParams = new URLSearchParams(search);
    const opportunityId = queryParams.get('opportunityId'); // Get 'opportunityId' from query params

    return (
        <Paper elevation={3} sx={{ p: 1, maxWidth: '100%', mt: 4 }}>
            <Typography variant="h1" component="h1" gutterBottom align="left">
                Repair Opportunity Details
            </Typography>
            <Box sx={{ mt: 0, display: 'flex', gap: 2 }}>
                {/* Left side (A) */}
                <Box sx={{ flex: 1, display: 'flex', flexDirection: 'column', gap: 2 }}>
                    <Box sx={{ flex: 1, p: 0 }}>
                        <GroupBox label="Recommendation">
                         </GroupBox>
                    </Box>
                    <Box sx={{ flex: 1, p: 0 }}>
                        <GroupBox label="Sales Performance & Pricing">
                         </GroupBox>
                    </Box>
                </Box>

                {/* Right side (B) */}
                <Box sx={{ flex: 1, display: 'flex', flexDirection: 'column', gap: 2 }}>
                    <Box sx={{ flex: 1, p: 0 }}>
                        <GroupBox label="Repair & Take Home">
                             
                        </GroupBox>
                    </Box>
                    <Box sx={{ flex: 1, p: 0 }}>
                        <GroupBox label="Repair Performance">
                         </GroupBox>
                    </Box>

                </Box>
            </Box>


        </Paper>
    );
}

export default RepairDetails;