// src/components/DWExplorer/NavigationMenu.js

import React from 'react';
import PropTypes from 'prop-types';
import {
  List,
  ListItemButton,
  ListItemText,
  ListSubheader,
  Divider,
} from '@mui/material';

const NavigationMenu = ({ tablesData, selectedTable, onTableSelect }) => {
  return (
    <List>
      {Object.keys(tablesData).map((section) => (
        <div key={section}>
          <ListSubheader component="div">{section}</ListSubheader>
          {tablesData[section].map((table) => (
            <ListItemButton
              key={table.id} // Use a unique identifier
              selected={selectedTable?.id === table.id}
              onClick={() => onTableSelect(table)}
            >
              <ListItemText 
                primary={table.tableName} // Pass string
                secondary={table.description} // Optional: Pass description as secondary text
              />
            </ListItemButton>
          ))}
          <Divider />
        </div>
      ))}
    </List>
  );
};

NavigationMenu.propTypes = {
  tablesData: PropTypes.object.isRequired, // e.g., { 'Data Warehouse': [...], 'Data Mart': [...] }
  selectedTable: PropTypes.object, // The selected table object
  onTableSelect: PropTypes.func.isRequired, // Function to handle table selection
};

export default NavigationMenu;
