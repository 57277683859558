import React, { useState, useEffect } from 'react';
import {
  Box,
  Typography,
  Grid,
  Paper
} from '@mui/material';
import useAuthenticatedData from '../../../hooks/useAuthenticatedData';
import KpisGrid from '../KpisGrid';
import MetricsGrid from '../MetricsGridxx';
import GoalsGrid from '../../overview/GoalsGrid';
import GroupBox from '../../common/GroupBox';
import { useMsal } from "@azure/msal-react";
import RepairOpportunitiesGrid from './RepairOpportunitiesGrid';
import ActionsGrid from './ActionsGrid';

const GridWrapper = ({ title, children }) => (
  <Paper sx={{ color: 'white', p: 2, mb: 2 }}>
    <Typography variant="h6" gutterBottom>{title}</Typography>
    {children}
  </Paper>
);

const RepairDashboard = () => {
  const { instance, accounts } = useMsal();
  const [username, setUsername] = useState("");

  useEffect(() => {
    if (accounts.length > 0) {
      const name = accounts[0].name || accounts[0].username || "User";
      setUsername(name);
    }
  }, [accounts]);

  return (
    <Paper elevation={3} sx={{ p: 2, maxWidth: '100%', mt: 4 }}>
      <Typography variant="h1" component="h1" gutterBottom align="left">
        Repair Dashboard
      </Typography>
      <Grid container spacing={2} sx={{ mt: 0 }}>
        {/* Left side (50%) */}
        <Grid item xs={12} md={6}>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
            <Box sx={{ flex: 1 }}>
              <GroupBox label="Actions">
                <ActionsGrid />
              </GroupBox>
            </Box>
            <Box sx={{ flex: 1 }}>
              <GroupBox label="Repair Goals">
                Placeholder for repair goals
              </GroupBox>
            </Box>
          </Box>
        </Grid>

        {/* Right side (50%) */}
        <Grid item xs={12} md={6}>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
            <Box sx={{ flex: 1 }}>
              <GroupBox label="Repair Opportunities">
                <RepairOpportunitiesGrid />
              </GroupBox>
            </Box>
            <Box sx={{ flex: 1 }}>
              <GroupBox label="Aging Repair Performance">
                Placeholder for aging repair performance
              </GroupBox>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default RepairDashboard;
