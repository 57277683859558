// src/components/DataExplorer/DetailView.js

import React from 'react';
import PropTypes from 'prop-types';
import { Typography, Paper, Box } from '@mui/material';

const DetailView = ({ tableName }) => {
  // Placeholder for actual detail logic
  // Replace this with actual data fetching or rendering logic
  return (
    <Box>
      <Typography variant="h4" gutterBottom>
        {tableName} Details
      </Typography>
      <Paper
        elevation={3}
        sx={{
          padding: 2,
          backgroundColor: 'background.paper',
        }}
      >
        {/* Replace this with actual detail content */}
        <Typography variant="body1">
          Detailed information about the {tableName} table goes here.
        </Typography>
      </Paper>
    </Box>
  );
};

DetailView.propTypes = {
  tableName: PropTypes.string.isRequired,
};

export default DetailView;
